import HomePage, { getStaticProps as fetcher, HomePageProps } from '@amf/shared/pages/HomePage';

import Layout from 'components/layout/Layout';
import { AppLinks } from 'utils/links';
import Meta from 'components/Meta';

export default function Page({ navigation, partners, ...props }: HomePageProps) {
  return (
    <div className='HomePage'>
      <Layout navigation={navigation} partners={partners}>
        <Meta />
        <HomePage
          {...props}
          disablePoints
          sliderButtonProps={{ variant: 'white-outline' }}
          newsListButtonProps={{ variant: 'dark-outline' }}
          links={{
            match: AppLinks.match,
            team: AppLinks.team,
            matches: AppLinks.matches,
            stats: AppLinks.stats,
            table: AppLinks.table,
            player: AppLinks.player,
            newsList: AppLinks.newsList,
          }}
        />
      </Layout>
    </div>
  );
}
export const getStaticProps = fetcher();
